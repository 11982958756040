.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // height: 100vh;
  background-color: #c3cbea;

  overflow: hidden;
  padding-top: 98px;
  @media screen and (max-width:714px)  {
    padding-top: 65px;
    }
  .owl-carousel {
    list-style: none;
    position: relative;

    .item {
      &::before {
        content: "";
        // background: linear-gradient(
        //   to left,
        //   rgb(66, 77, 121, 0.5),
        //   transparent
        // );
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
      }
      color: #fff;
      z-index: 0;
      width: 100%;
      height: calc(100vh - 98px);
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      background-size: cover !important;
      &.slide1 {
        background: url(../../assets/images/slide1.jpeg) center center no-repeat;
      }
      &.slide2 {
        background: url(../../assets/images/slide2.jpeg) center center no-repeat;
      }
      &.slide3 {
        background: url(../../assets/images/slide3.jpeg) center center no-repeat;
      }
      &.slide4 {
        background: url(../../assets/images/slide4.jpeg) center center no-repeat;
      }
      &.slide5 {
        background: url(../../assets/images/slide5.jpeg) center center no-repeat;
      }

      .slider-info {
        margin: 50px 100px;
        padding: 50px;
        background: rgb(66, 77, 121, 0.4);
        border-radius: 25px;
      }
    }
    .owl-dots {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
    }
    .owl-nav {
      // position: absolute;
      width: 100%;
      text-align: center;
      margin: 0;
      button {
        position: absolute;
        top: 50%;
        background-color: #424d79 !important;
        font-size: 50px !important;
        padding: 15px !important;
        display: flex;
        color: #fff;
        span {
          margin-top: -15px;
          line-height: 1;
        }
        &.owl-next {
          right: 0;
        }
        &.owl-prev {
          left: 0;
        }
      }
    }
    &.owl-rtl {
      .owl-nav button {
        transform: rotate(180deg);
      }
    }
  }
}

.title {
  @media screen and (max-width: 714px) {
    margin-top: 5em;
  }

  @media screen and (min-width: 1024px) and (max-width: 1080px) {
    margin-top: 5em;
  }

  h1 {
    color: #424d79;
    font-size: 3rem;
    font-weight: 700;
    text-shadow: 2px 2px 8px #7280b9a6;

    @media screen and (min-width: 714px) and (max-width: 1024px) {
      font-size: 4rem;
    }

    @media screen and (min-width: 1024px) and (max-width: 1080px) {
      font-size: 2.5rem;
    }
  }
}

.home-card {
  max-width: 100%;
  max-width: 100%;
  width: 30%;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.527);
  padding: 1em;

  @media screen and (max-width: 714px) {
    width: 100%;
  }

  @media screen and (min-width: 714px) and (max-width: 1024px) {
    width: 70%;
  }

  @media screen and (min-width: 1024px) and (max-width: 1080px) {
    width: 40%;
  }

  .img-container {
    width: 90%;
    margin: auto;
    background-color: rgb(255, 255, 255);
    text-align: center;
    padding: 1em;
    border-radius: 12px;

    img {
      width: 100%;
      border-radius: 12px;
    }
  }

  .card-body {
    h5 {
      @media screen and (min-width: 714px) and (max-width: 1024px) {
        font-size: 2rem;
        font-weight: 700;
      }
    }

    span {
      @media screen and (min-width: 714px) and (max-width: 1024px) {
        font-size: 1.5rem;
      }
    }
  }
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.d-flex-start {
  display: flex;
  justify-content: flex-start;
}

// sec__two
.sec-two {
  background-color: #f0f3ff;
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  @media screen and (max-width: 714px) {
    // height: 140vh;
  }

  @media screen and (min-width: 714px) and (max-width: 1024px) {
    // height: 100vh;
  }
  ul {
    padding: 0 2rem;
  }
}

.img-sec-two {
  width: 50%;
  // background-color: white;

  @media screen and (max-width: 714px) {
    width: 80%;
    margin: auto;
  }

  @media screen and (min-width: 714px) and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (min-width: 1024px) and (max-width: 1080px) {
    width: 50%;
  }

  img {
    width: 100%;
  }
}

.sec-two-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  align-items: center;
  // height: 100%;

  h2 {
    font-size: 3.8rem;
    font-weight: 700;
    color: #424d79;
    position: relative;
    z-index: 9;

    @media screen and (max-width: 714px) {
      font-size: 1.8rem;
      text-align: center;
      margin-top: 2em;
    }

    @media screen and (min-width: 714px) and (max-width: 1024px) {
      font-size: 1.8rem;
    }

    &::after {
      content: "“";
      position: absolute;
      top: -86px;
      left: -29px;
      opacity: 0.5;
      z-index: -1;
      font-size: 17rem;

      @media screen and (max-width: 714px) {
        font-size: 5rem;
        top: -20px;
        left: -2px;
      }

      @media screen and (min-width: 714px) and (max-width: 1024px) {
        font-size: 5rem;
        top: -20px;
        left: -2px;
      }
    }
  }

  p {
    font-size: 1.2rem;
    margin-top: 2em;

    @media screen and (max-width: 714px) {
      font-size: 1rem;
      text-align: center;
    }
  }
}
.service-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .service-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 1.1em;
    color: #333;
    transition: background-color 0.3s, color 0.3s;

    i {
      margin: auto 10px;
      font-size: 1.5em;
      color: #424d79;
    }

    &:hover {
      background-color: #424d79;
      color: #fff;

      i {
        color: #fff;
      }
    }
  }
}
.title-number {
  p {
    font-size: 1.2rem;
    font-weight: 600;
  }

  span {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.sec-three {
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  @media screen and (max-width: 375px) {
    height: 214vh !important; //draz
  }
  @media screen and (max-width: 714px) {
    height: 143vh;
  }

  @media screen and (min-width: 714px) and (max-width: 1024px) {
    height: 125vh;
  }
  .services-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
    // padding: 20px;

    .service-card {
      width: 250px;
      background-color: #fff;
      border-radius: 8px;
      text-align: center;
      padding: 20px 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s, color 0.3s;
      color: #333;

      i {
        font-size: 2em;
        color: #ad0000;
        margin-bottom: 10px;
        transition: color 0.3s;
      }

      h3 {
        font-size: 1.2em;
        margin: 0;
        transition: color 0.3s;
      }

      &:hover {
        background-color: #424d79;
        color: #fff;

        i {
          color: #fff;
        }

        h3 {
          color: #fff;
        }
      }
    }
  }
}

.sce-s {
  width: 100%;

  @media screen and (max-width: 714px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1em;
  }

  .img-sec-s {
    img {
      width: 22%;
      margin-bottom: 1em;

      @media screen and (max-width: 714px) {
        width: 100%;
      }

      @media screen and (min-width: 714px) and (max-width: 1024px) {
        width: 40%;
      }
    }

    .img-handle {
      margin-bottom: 1.5em;
      width: 32%;

      @media screen and (max-width: 714px) {
        width: 100%;
      }

      @media screen and (min-width: 714px) and (max-width: 1024px) {
        width: 60%;
      }
    }
  }

  h4 {
    color: #424d79;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1em;

    @media screen and (min-width: 714px) and (max-width: 1024px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1rem;
    color: #1e3240;
    font-weight: 600;

    @media screen and (max-width: 714px) {
      text-align: center;
    }

    @media screen and (min-width: 714px) and (max-width: 1024px) {
      font-size: 1.5rem;
    }
  }

  .learn-more {
    background-color: #424d79;
    color: white;
    padding: 0.5em 0.5em;
    border: 1px solid #424d79;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: transparent;
      border: 1px solid #424d79;
      color: #424d79;
    }
  }
}
.mtClass {
  //draz
  margin-top: 20rem;
  @media screen and (max-width: 375px) {
    margin-top: 23rem !important;
  }
}

.sec-four {
  // height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  // @media screen and (min-width: 1080px) {
  //   height: 122vh;
  // }
  // @media screen and (max-width: 375px) {
  //   height: 90vh; //draz
  // }
  // @media screen and (min-width: 714px) and (max-width: 1024px) {
  //   height: 166vh;
  // }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #424d79;
  color: white;
}
.projects {
  display: flex;
  padding: 20px;

  &__tabs {
    width: 30%;
    background-color: #f4f4f4;
    padding: 10px;

    .tabs {
      list-style: none;
      padding: 0;

      .tab {
        padding: 10px 15px;
        cursor: pointer;
        background-color: #ddd;
        margin-bottom: 5px;
        transition: background 0.3s;

        &:hover {
          background-color: #ccc;
        }

        &.active {
          background-color: #bbb;
          font-weight: bold;
        }
      }
    }
  }

  &__slider {
    width: 70%;
    padding: 10px;
    position: relative;
    overflow: hidden;

    .slider {
      display: flex;
      transition: transform 0.5s ease-in-out;

      .slide {
        display: none;
        width: 100%;
        align-items: center;
        justify-content: center;

        &.active {
          display: flex;
        }

        img {
          width: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}

// sec-five

.sec-five {
  // height: 50vh;
  background-color: #f0f3ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}

.about-content {
  @media screen and (max-width: 714px) {
    text-align: center;
  }

  h2 {
    color: #424d79;
    font-weight: 700;
  }

  p {
    color: #1e3240;
    font-size: 2rem;
  }
}

.sec-six {
  overflow: hidden;
  background-color: #c3cbea;
}

.left-part {
  background-color: #c3cbea;
  padding: 3em;

  h3 {
    color: #424d79;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1em;
  }
  .form-group {
    margin-bottom: 15px;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  input:focus, textarea:focus {
    outline: none;
    border-color: #424D79;
  }
  
  textarea {
    resize: vertical; /* Allows vertical resizing */
  }
  
  .text-danger {
    font-size: 12px;
    color: #e74c3c;
    margin-top: 5px;
  }
  
  button.send {
    padding: 10px 20px;
    background-color: #424D79;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  button.send:hover {
    background-color: #363536;
  }
  
  button.send:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  // form {
  //   input {
  //     background-color: transparent;
  //     border: 1px solid #1e3240;
  //     width: 100%;

  //     height: 2rem;
  //   }

  //   .form-group {
  //     margin-bottom: 1.5em;
  //   }

  //   textarea {
  //     background-color: transparent;
  //     border: 1px solid #1e3240;
  //     width: 100%;
  //   }

  //   .send {
  //     background-color: #424d79;
  //     color: white;
  //     border: 1px solid #424d79;
  //     padding: 0.5em 1.5em;
  //     transition: all 0.3s ease-in-out;
  //     margin-top: 1em;

  //     &:hover {
  //       background-color: transparent;
  //       border: 1px solid #424d79;
  //       color: #424d79;
  //     }
  //   }
  // }
}

.google-map-code {
  width: 100%;
  height: 100%;
  iframe {
    // border-radius: 20px !important;
    width: 100%;
    height: 100%;
    // box-shadow: 10px 5px 5px rgb(218, 218, 218);

    // @media screen and (max-width: 714px) {
    //   width: 15rem;
    //   height: 15rem;
    //   margin: 1em;
    // }

    // @media screen and (min-width: 714px) and (max-width: 1024px) {
    //   width: 22rem;
    //   height: 22rem;
    // }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  width: 24rem;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 714px) {
    width: 10rem;
  }

  &:hover .layout {
    top: 0;
  }
  .layout {
    position: absolute;
    top: 200%;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;

    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1em;

    transition: all 0.5s ease-in-out;
    .project-btn {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 12px;
      opacity: 0;
    }
    h6 {
      color: white;
      font-size: 1.6rem;
      font-weight: 700;
      @media screen and (max-width: 714px) {
        font-size: 0.8rem;
      }
    }
    p {
      color: white;
      @media screen and (max-width: 714px) {
        font-size: 0.7rem;
      }
    }
  }
}
$rtl: rtl;
.carousel-control-next {
  top: 50%;
  left: 88%;
  @media screen and (max-width: 714px) {
    left: 51%;
    top: 72%;
    width: 39px;
    height: 39px;
    @if rtl {
      right: 43%;
    } @else {
      left: 51%;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1080px) {
    right: 6%;
  }
  @media screen and (min-width: 1080px) {
    right: 6%;
  }
}

.carousel-control-prev {
  top: 50%;
  left: 6%;
  @media screen and (max-width: 714px) {
    left: 43%;
    top: 72%;
    width: 39px;
    height: 39px;
    direction: rtl;
  }
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  @media screen and (max-width: 714px) {
    width: 1rem;
  }
}
.carousel-inner {
  width: 875px;
}
.btn-all {
  background-color: #424d79;
  color: white;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-left: 1rem;
}

.company-data {
  .address {
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
  }
  .phone {
    @extend .address;
  }
  .email {
    @extend .address;
  }
}
.PageCover {
  position: relative;
  margin-top: 95px;
  padding: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../assets/images/cover.jpeg) center center no-repeat;
  background-size: cover;
  z-index: 0;
  @media screen and (max-width:714px)  {
    margin-top: 65px;
    }
  &::before{
    content: "";
    position:absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(66, 77, 121);
    background: linear-gradient(
      130deg,
      rgba(66, 77, 121, 1) 42%,
      rgba(173, 0, 0, 1) 100%
    );
    opacity: 0.5;
    z-index: 0;
  }
  h2{
    color: #fff;
    z-index: 5;
    text-transform: uppercase;
    text-align: center;
  }
}
