.aboutUs{
    ul {
        list-style-type: none; // Remove default bullet points
        padding: 0;
        margin: 0;
      
        li {
        //   font-family: 'Georgia', serif; // Elegant serif font
        //   font-size: 1.1rem;
        //   line-height: 1.8; // Increase line spacing for readability
        //   color: #444; // Dark gray for a softer look
          padding: 0.5rem 0; // Space between items
          position: relative;
          transition: color 0.3s ease; // Smooth color transition
      
          // Custom bullet styling
          &::before {
            content: ''; // Empty content for custom bullet
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #424d79; // Bullet color
            border-radius: 50%; // Circle bullet shape
            margin: 0 0.75rem; // Space between bullet and text
            transition: background-color 0.3s ease;
            vertical-align: middle;
          }
      
          // Hover effect for list item and bullet
          &:hover {
            color: #ad0000; // Color change on hover
      
            &::before {
              background-color: #ad0000; // Match bullet color on hover
            }
          }
        }
      }
      .image-box {
        position: relative;
        width: 100%;  // or specify a specific width if needed
        height: 100%; // or specify a specific height if needed
        
        img {
          position: absolute;
          @media (max-width:991px){
            position: relative;

          }

          &.img1 {
            width: 75%;
            top: 0;
            right: 0;
            @media (max-width:991px){
                width: 50%;
              }
          }
          
          &.img2 {
            width: 65%; // adjust as needed
            bottom: 0;
            left: 0;
            z-index: 1; // ensure it's in front of img1
            @media (max-width:991px){
                width: 50%;
              }
          }
        }
      }  
}