.sec-footer{
    background-color: #f0f3ff;
  
    .footer{
        width: 100%;
        height: 100px;
    
        .footer-body{
            display: flex;
            align-items: center;
            justify-content: space-around;
           
        }
    }
    
    .container-fluid {
        margin-top: 50px
    }
    
    .footer-copyright {
        margin-top: 13px
    }
    .company-data{
        color: #777a7c;
    }
    a {
        text-decoration: none !important;
        color: #777a7c
    }
    
    .description {
        font-size: 12px
    }
    
    // .fa-facebook-f {
    //     color: #3b5999
    // }
    
    // .fa-instagram {
    //     color: #e4405f
    // }
    
    // .fa-youtube {
    //     color: #cd201f
    // }
    
    // .fa-twitter {
    //     color: #55acee
    // }
    
    .logo-footer{
        height:150px;
    }
    
    .footer-copyright p {
        margin-top: 10px
    }
}