@keyframes load {
    0%{
        opacity: 0.08;

				filter: blur(5px);
				letter-spacing: 3px;
        }
   
}
.loadingScreen{
    background-color: #000000AA;
    display:flex;
    justify-content: center;
    align-items: center;
    height:100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    .animate {
        margin: auto;
        color: white;
        font-family: Helvetica, sans-serif, Arial;
        animation: load 1.2s infinite 0s ease-in-out;
        animation-direction: alternate;
        text-shadow: 0 0 1px white;
        
    }
}